import React from "react"

const createOpeningHours = (day, hours) => ({ day, hours })

const openingHours = [
  createOpeningHours("Monday", "closed"),
  createOpeningHours("Tuesday", "12:30–3:30pm, 7:30-11pm"),
  createOpeningHours("Wednesday", "12:30–3:30pm, 7:30–11pm"),
  createOpeningHours("Thursday", "12:30–3:30pm, 7:30–11pm"),
  createOpeningHours("Friday", "12:30–3:30pm, 7:30–11pm"),
  createOpeningHours("Saturday", "12:30–3:30pm, 7:30–11pm"),
  createOpeningHours("Sunday", "closed"),
]

const OpeningHourListItem = ({ day, hours }) => (
  <div className="row">
    <span>{day}</span>
    <span>{hours}</span>
  </div>
)

const Footer = () => {
  return (
    <footer>
      <section>
        <div className="container">
          <h3>Where are we?</h3>
          <a href="https://www.google.com/maps/place/Perla+kitchen+%26+bar/@39.8889896,4.2608244,17z/data=!4m12!1m6!3m5!1s0x12958790be281aa9:0x1b4caa4f8b85db2d!2sPerla+kitchen+%26+bar!8m2!3d39.8889855!4d4.2630184!3m4!1s0x12958790be281aa9:0x1b4caa4f8b85db2d!8m2!3d39.8889855!4d4.2630184">
            <div>Carrer D'Alaior 12</div>
            <div>07703</div>
            <div>Mahón</div>
            <div>Menorca</div>
          </a>
        </div>
        <div className="container">
          <h3>Contact us</h3>
          <a
            css={{ paddingTop: 12.5, paddingBottom: 12.5 }}
            href="tel:+34971945363"
          >
            +34 971 94 53 63
          </a>
          <a
            css={{ paddingTop: 12.5, paddingBottom: 12.5 }}
            href="tel:+34636977270"
          >
            +34 636 97 72 70
          </a>
          <a
            css={{ paddingTop: 12.5, paddingBottom: 12.5 }}
            href="mailto:perlakitchenbar@gmail.com, vedran.marjanovic@gmail.com"
          >
            perlakitchenbar@gmail.com vedran.marjanovic@gmail.com
          </a>
          <br />
          <span>
            <a href="https://www.facebook.com/perlakitchenandbar">
              <svg
                width="48"
                height="48"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"
                />
              </svg>
            </a>{" "}
            <a href="https://www.instagram.com/perlakitchenandbar/">
              <svg
                width="48"
                height="48"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
            </a>
          </span>
        </div>
        <div className="container">
          <h3>Opening hours</h3>
          {openingHours.map((openingHours, index) => (
            <OpeningHourListItem key={index} {...openingHours} />
          ))}
        </div>
      </section>
    </footer>
  )
}

export default Footer
